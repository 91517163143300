<template>
	<div class="partial partial--bios bios" :style="pageStyles">
		<article-wrapper
			:progressColor="content.progressColor"
			>
			<template v-slot:hero>
				<div class="bios__hero" :style="heroStyles">
					<div class="bios__hero-container">
						<div class="bios__hero-image" v-if="content.image">
							<img :src="require(`@/assets/bios/${content.image}`)" :alt="content.name" />
						</div>
						<div class="bios__hero-name">{{ content.name }}</div>
						<div class="bios__hero-title">{{ content.title }}</div>
					</div>
				</div>
			</template>
			<template v-slot:body>
				<div class="bios__body" v-html="content.content"></div>
			</template>

			<template v-slot:footer>
				<site-footer class="bio__footer" />
			</template>
		</article-wrapper>
	</div>
</template>

<script>
import ArticleWrapper from '@/components/ArticleWrapper'
import SiteFooter from '@/components/SiteFooter'

export default {
	name: 'PartialBios',
	components: {
		ArticleWrapper,
		SiteFooter
	},
	data: () => ({
		personActive: 0,
	}),
	mounted() {
		const el = this.$el.querySelector('.bios__hero')
		this.$store.dispatch('nav/setLastState', el)
	},
	computed: {
		bios() {
			const content = require(`@/assets/content/bios/filler.json`)
			return content || []
		},
		content() {
			return this.bios[this.personActive]
		},
		next() {
			return this.bios[this.personActive + 1]
		},
		heroStyles() {
			const styles = {}
			if (this.content.background) {
				styles.backgroundImage = `url(${require('@/assets/bios/'+ this.content.background)})`
			}
			return styles
		},
		pageStyles() {
			return {}
		},
	},
	watch: {
		$route: {
			immediate: true,
			handler: function(val) {
				const { person } = val.params
				const personIdx = this.bios.findIndex(s => s.id == person) || 0

				this.personActive = personIdx
			},
		},
	},
}
</script>

<style scoped lang="scss">
.bios {
	background-color: white;

	&__hero {
		@include gutter('padding');
		background-repeat: no-repeat;
		background-size: cover;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: center;
		//height: 100%;

		&-container {
			@include gutter('padding-top', 4.5);
			@include gutter('padding-bottom', 2);
			display: flex;
			flex-direction: column;
			width: 100%;

			@include media-query-min-width('tablet') {
				justify-content: flex-end;
				@include layout();
			}
		}

		&-image {
			@include gutter('margin-bottom', 1);
			border: 4px solid white;
			border-radius: 50%;
			height: 128px;
			overflow: hidden;
			width: 128px;

			@include media-query-min-width('tablet') {
				height: 200px;
				width: 200px;
			}

			img {
				max-width: 100%;
			}
		}

		&-name {
			@include fontSize(48px);
			@include gutter('margin-bottom', .5);
			color: color_('primary', 'text');
			font-family: $font-family-secondary;
			font-weight: bold;
			text-transform: uppercase;
			line-height: .9;

			@include media-query-min-width('tablet') {
				@include fontSize(72px);
			}
		}

		&-title {
			@include fontSize(14px);
			color: color_('primary', 'text');

			@include media-query-min-width('tablet') {
				@include fontSize(18px);
			}
		}
	}

	&__body {
		@include gutter('padding', 1);
		@include gutter('padding-top', 2);
		@include fontSize(18px);
		line-height: 1.5;
		margin-left: auto;
		margin-right: auto;
		width: 100%;

		@include media-query-min-width('tablet') {
			@include gutter('padding', 3);
		}

		@include media-query-min-width('desktop') {
			@include gutter('padding-top', 5);
			max-width: $content-container-lg;
		}
	}
}
</style>
