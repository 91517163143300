<template>
	<div class="article-wrapper">
		<div class="article-progress">
			<div class="article-progress__track">
				<div class="article-progress__indicator" :style="progressStyle"></div>
			</div>
		</div>
		<div class="article-nav">
			<div class="article-nav__content">
				<div class="article-nav__logo">
					<router-link :to="{name: 'dashboard' }">
						<img src="~@/assets/logo_dtcc-white.png" alt="DTCC" width="98" />
					</router-link>
				</div>
				<div class="article-nav__actions">
					<router-link :to="{name: 'dashboard' }">
						<close-icon :color="`#ffffff`" />
					</router-link>
				</div>
			</div>
		</div>

		<slot name="hero" v-if="$slots.hero">[hero here]</slot>

		<div class="article-wrapper__body">
			<slot name="body">[body here]</slot>
		</div>

		<div class="article-wrapper__footer" v-if="$slots.footer">
			<slot name="footer">[next here]</slot>
		</div>
	</div>
</template>

<script>
import CloseIcon from '@/components/CloseIcon'

export default {
	name: 'ArticleWrapper',
	components: {
		CloseIcon
	},
	props: {
		progressColor: {
			type: String,
			default: `rgba(200, 200, 200, 0.5)`
		},
	},
	data: () => ({
		maxHeight: 0,
		ticking: false,
		scrollPos: 0,
		scrollPercentage: 0,
	}),
	mounted() {
		this.$el.addEventListener('scroll', this.onScroll)
		window.addEventListener('resize', this.calculateHeights)
		this.calculateHeights()
	},
	destroyed() {
		this.$el.removeEventListener('scroll', this.onScroll)
		window.removeEventListener('resize', this.calculateHeights)
	},
	methods: {
		onScroll() {
			this.scrollPos = this.$el.scrollTop
			if (!this.ticking) {
				requestAnimationFrame(this.calculateScroll)
				this.ticking = true
			}
		},
		calculateScroll() {
			this.scrollPercentage = this.scrollPos / this.maxHeight
			this.ticking = false
		},
		calculateHeights() {
			this.maxHeight = (this.$el.scrollHeight - window.innerHeight)
			this.calculateScroll()
		},
	},
	computed: {
		progressStyle() {
			return {
				backgroundColor: this.progressColor,
				transform: `scaleX(${this.scrollPercentage})`,
			}
		},
	}
}
</script>

<style scoped lang="scss">
.article-wrapper {
	display: flex;
	flex-direction: column;
	height: 100vh;
	overflow-y: auto;
	overflow-x: hidden;
	position: relative;
	width: 100%;

	&__hero {
		//@include ratio('film');
	}

	&__header {
		display: flex;
		justify-content: space-between;
	}

	&__body {
		flex: 1;
		white-space: pre-line;
	}
}

.article-nav {
	display: flex;
	justify-content: center;
	//background: linear-gradient(0deg, rgba(0,0,0,0) 25%, rgba(0,0,0,1) 100%);
	position: absolute;
	width: 100%;
	z-index: 1;

	&__content {
		@include gutter('padding-top', 1.25);
		@include gutter('padding-bottom', 1.25);
		@include layout();
		display: flex;
		align-items: center;
		justify-content: space-between;
		max-width: $container-max-width;
		//margin: 0 auto;
		width: 100%;
		font-family: $font-family-secondary;

		@include media-query-min-width('desktop') {
			@include gutter('padding-top', 2.5);
			@include gutter('padding-bottom', 2.5);
		}
	}

	&__logo img {
		animation: fadeIn 2000ms $ease-out 500ms 1 forwards;
		opacity: 0;
		max-width: 50px;

		@include media-query-min-width('tablet') {
			max-width: initial;
		}
	}

	&__actions {
		transition: opacity $transition-1;
		opacity: 1;

		svg {
			animation: fadeIn 2000ms $ease-out 500ms 1 forwards;
			opacity: 0;
			max-width: 20px;

			@include media-query-min-width('tablet') {
				max-width: initial;
			}
		}

		&:hover {
			opacity: .5;
		}
	}
}

.article-progress {
	height: 10px;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	width: 100%;
	z-index: 99;

	&__track {
		height: 100%;
	}

	&__indicator {
		background-color: red;
		height: 100%;
		transform: scaleX(0);
		animation-name: progressIndicator;
		// animation-fill-mode: forwards;
		animation-timing-function: linear;
		transform-origin: left center;
	}
}

@keyframes fadeIn {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}
</style>
