<template>
	<div class="footer">
		<div class="footer__content">
			<div class="footer__nav">
				<a href="https://www.dtcc.com/" target="_blank"><strong>DTCC.COM</strong></a>
				<a href="https://www.dtcc.com/terms" target="_blank">Terms of use</a>
				<a href="https://www.dtcc.com/privacy" target="_blank">Privacy policy</a>
			</div>

			<div class="footer__social">
				<a href="https://www.linkedin.com/company/dtcc" target="_blank" >
					<svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21">
						<path id="Icon_awesome-linkedin" data-name="Icon awesome-linkedin" d="M19.5,1.5H1.5A1.506,1.506,0,0,0,0,3.014V20.986A1.506,1.506,0,0,0,1.5,22.5h18A1.51,1.51,0,0,0,21,20.986V3.014A1.51,1.51,0,0,0,19.5,1.5ZM6.347,19.5H3.234V9.478H6.352V19.5ZM4.791,8.109A1.8,1.8,0,1,1,6.6,6.3,1.805,1.805,0,0,1,4.791,8.109ZM18.014,19.5H14.9V14.625c0-1.163-.023-2.658-1.617-2.658-1.622,0-1.87,1.266-1.87,2.573V19.5H8.3V9.478h2.986v1.369h.042A3.278,3.278,0,0,1,14.278,9.23c3.15,0,3.736,2.077,3.736,4.777Z" transform="translate(0 -1.5)" fill="#003956"/>
					</svg>
				</a>

				<a href="https://twitter.com/The_DTCC" target="_blank">
					<svg xmlns="http://www.w3.org/2000/svg" width="24" height="19.492" viewBox="0 0 24 19.492">
						<path id="Icon_awesome-twitter" data-name="Icon awesome-twitter" d="M21.533,7.112c.015.213.015.426.015.64A13.9,13.9,0,0,1,7.553,21.746,13.9,13.9,0,0,1,0,19.538a10.176,10.176,0,0,0,1.188.061,9.851,9.851,0,0,0,6.107-2.1,4.927,4.927,0,0,1-4.6-3.411,6.2,6.2,0,0,0,.929.076,5.2,5.2,0,0,0,1.294-.167A4.919,4.919,0,0,1,.975,9.168V9.107A4.954,4.954,0,0,0,3.2,9.731,4.926,4.926,0,0,1,1.675,3.152,13.981,13.981,0,0,0,11.817,8.3,5.553,5.553,0,0,1,11.7,7.173a4.923,4.923,0,0,1,8.513-3.365A9.684,9.684,0,0,0,23.33,2.619,4.906,4.906,0,0,1,21.167,5.33,9.861,9.861,0,0,0,24,4.569a10.573,10.573,0,0,1-2.467,2.543Z" transform="translate(0 -2.254)" fill="#003956"/>
					</svg>
				</a>

				<a href="https://www.facebook.com/TheDTCC/" target="_blank">
					<svg xmlns="http://www.w3.org/2000/svg" width="23.25" height="23.109" viewBox="0 0 23.25 23.109">
						<path id="Icon_awesome-facebook" data-name="Icon awesome-facebook" d="M23.625,12A11.625,11.625,0,1,0,10.184,23.484V15.36H7.23V12h2.953V9.439c0-2.913,1.734-4.523,4.391-4.523a17.891,17.891,0,0,1,2.6.227V8H15.711a1.68,1.68,0,0,0-1.894,1.815V12H17.04l-.516,3.36H13.816v8.124A11.629,11.629,0,0,0,23.625,12Z" transform="translate(-0.375 -0.375)" fill="#003956"/>
					</svg>
				</a>

				<a href="https://www.youtube.com/c/thedtcc1973" target="_blank">
					<svg xmlns="http://www.w3.org/2000/svg" width="25.602" height="18" viewBox="0 0 25.602 18">
						<path id="Icon_awesome-youtube" data-name="Icon awesome-youtube" d="M25.765,5.816A3.217,3.217,0,0,0,23.5,3.538C21.505,3,13.5,3,13.5,3s-8.005,0-10,.538A3.217,3.217,0,0,0,1.235,5.816a33.744,33.744,0,0,0-.535,6.2,33.744,33.744,0,0,0,.535,6.2A3.169,3.169,0,0,0,3.5,20.462c2,.538,10,.538,10,.538s8.005,0,10-.538a3.169,3.169,0,0,0,2.263-2.242,33.744,33.744,0,0,0,.535-6.2,33.744,33.744,0,0,0-.535-6.2ZM10.882,15.825V8.212l6.691,3.806Z" transform="translate(-0.699 -3)" fill="#003956"/>
					</svg>
				</a>
			</div>
		</div>
	</div>
</template>

<script>

// Export
export default {
	name: 'SiteFooter'
}
</script>

<style lang="scss" scoped>

.footer {
	@include gutter('padding', 1);
	border-top: 1px solid color_('background','light');

	@include media-query-min-width('tablet') {
		@include gutter('padding', 1);
	}

	&__content {
		@include layout();
		display: flex;
		justify-content: space-between;
		flex-direction: column;

		@include media-query-min-width('tablet') {
			flex-direction: row;
		}
	}

	&__nav {
		margin-bottom: 1em;

		@include media-query-min-width('tablet') {
			margin-bottom: 0;
		}

		a {
			color: color_('primary','dark');
			margin: 0 1em;
			transition: opacity $transition-1;
			opacity: 1;

			&:hover {
				opacity: .5;
			}

			&:first-of-type {
				margin-left: 0;
			}
		}
	}

	&__social a {
		color: color_('primary','dark');
		margin: 0 1em;
		transition: opacity $transition-1;
		opacity: 1;

		&:hover {
			opacity: .5;
		}

		&:first-of-type {
				margin-left: 0;
			}

		&:last-of-type {
			margin-right: 0;
		}
	}
}

</style>
